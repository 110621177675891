import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from "./pages/Home";
import Admin from "./pages/Admin";


function App(){

  return (
    <Router >



        {/* <Switch >
        <Route exact path = "/" >
          <Home/> 
        </Route> 
      <Route exact path = "/admin" >
        <Admin/>
        </Route>
        </Switch>  */}

        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/Admin" element={<Admin/>}/>
        </Routes>


 
      </Router> 


    
  );
}

export default App;
